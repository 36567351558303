import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type OrganizationSchema } from './types'
import { getSanityImageUrl } from '@lib/image'

export const getOrganizationSchema = (site: SanitySiteFragment) => {
  const organization = site.schemaSettings?.organization
  const imageUrl = getSanityImageUrl(organization?.logo)
  const organizationSchema: OrganizationSchema = {
    '@type': 'Organization',
    name: organization?.name,
    url: organization?.url,
    logo: imageUrl,
    image: imageUrl,
    telephone: organization?.phone,
    email: organization?.email,
    sameAs: organization?.sameAsUrls,
  }

  if (organization?.phone || organization?.email) {
    organizationSchema.contactPoint = [
      {
        '@type': 'ContactPoint',
        telephone: organization?.phone,
        email: organization?.email,
      },
    ]
  }

  if (organization?.address?.country) {
    organizationSchema.address = {
      '@type': 'PostalAddress',
      addressCountry: organization?.address?.country,
      addressLocality: organization?.address?.locality,
      addressRegion: organization?.address?.region,
      postOfficeBoxNumber: organization?.address?.postOfficeBoxNumber,
      postalCode: organization?.address?.postalCode,
      streetAddress: organization?.address?.streetAddress,
    }
  }

  return JSON.stringify(organizationSchema)
}
